import { NormalizedFieldEntry } from '~/types/sourceValue'

export const NORMALIZED_FIELD_DEFINITION: {
  [key in NormalizedFieldEntry]?: {
    definition: string
  }
} = {
  [NormalizedFieldEntry.ABSENTEEISM_CATEGORY__IS_PLANNED]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.ABSENTEEISM_CATEGORY__IS_PLANNED}`
  },
  [NormalizedFieldEntry.ABSENTEEISM_TYPE__IS_PLANNED]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.ABSENTEEISM_TYPE__IS_PLANNED}`
  },
  [NormalizedFieldEntry.ABSENTEEISM_CATEGORY__IS_PLANNED_IN_EGAPRO_INDEX]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.ABSENTEEISM_CATEGORY__IS_PLANNED_IN_EGAPRO_INDEX}`
  },
  [NormalizedFieldEntry.ABSENTEEISM_CATEGORY__MATERNITY_TYPE_IN_EGAPRO_INDEX]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.ABSENTEEISM_CATEGORY__MATERNITY_TYPE_IN_EGAPRO_INDEX}`
  },
  [NormalizedFieldEntry.ABSENTEEISM_CATEGORY__IS_REAL]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.ABSENTEEISM_CATEGORY__IS_REAL}`
  },
  [NormalizedFieldEntry.ABSENTEEISM_CATEGORY__TYPE]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.ABSENTEEISM_CATEGORY__TYPE}`
  },
  [NormalizedFieldEntry.MOBILITY_CATEGORY__TYPE]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.MOBILITY_CATEGORY__TYPE}`
  },
  [NormalizedFieldEntry.DEPARTURE_REASON_TYPE__IS_REGRETTED]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.DEPARTURE_REASON_TYPE__IS_REGRETTED}`
  },
  [NormalizedFieldEntry.DEPARTURE_REASON_CATEGORY__IS_REGRETTED]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.DEPARTURE_REASON_CATEGORY__IS_REGRETTED}`
  },
  [NormalizedFieldEntry.DEPARTURE_REASON_CATEGORY__TYPE]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.DEPARTURE_REASON_CATEGORY__TYPE}`
  },
  [NormalizedFieldEntry.PROFESSIONAL_CATEGORY__TYPE_IN_EGAPRO_INDEX]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.PROFESSIONAL_CATEGORY__TYPE_IN_EGAPRO_INDEX}`
  },
  [NormalizedFieldEntry.EMPLOYMENT_CATEGORY__TYPE]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.EMPLOYMENT_CATEGORY__TYPE}`
  },
  [NormalizedFieldEntry.SALARY_CATEGORY__TYPE]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.SALARY_CATEGORY__TYPE}`
  },
  [NormalizedFieldEntry.PAYROLL_CATEGORY__IS_IN_EGAPRO_INDEX]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.PAYROLL_CATEGORY__IS_IN_EGAPRO_INDEX}`
  },
  [NormalizedFieldEntry.PAYROLL_CATEGORY__TYPE]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.PAYROLL_CATEGORY__TYPE}`
  },
  [NormalizedFieldEntry.HIRING_TYPE__TYPE]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.HIRING_TYPE__TYPE}`
  },
  [NormalizedFieldEntry.RECRUITMENT_REJECTION_CATEGORY__TYPE]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.RECRUITMENT_REJECTION_CATEGORY__TYPE}`
  },
  [NormalizedFieldEntry.RECRUITMENT_STAGE__IS_REJECTED]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.RECRUITMENT_STAGE__IS_REJECTED}`
  },
  [NormalizedFieldEntry.RECRUITMENT_STAGE__TYPE]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.RECRUITMENT_STAGE__TYPE}`
  },
  [NormalizedFieldEntry.JOB_STATUS__TYPE]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.JOB_STATUS__TYPE}`
  },
  [NormalizedFieldEntry.REMOTE_STATUS__TYPE]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.REMOTE_STATUS__TYPE}`
  },
  [NormalizedFieldEntry.MANAGEMENT_CATEGORY__IS_MANAGER]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.MANAGEMENT_CATEGORY__IS_MANAGER}`
  },
  [NormalizedFieldEntry.HIRING_SOURCE__TYPE]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.HIRING_SOURCE__TYPE}`
  },
  [NormalizedFieldEntry.NATIONALITY__TYPE]: {
    definition: `settings.dataNormalization.module.normalizationFieldDefinitions.${NormalizedFieldEntry.NATIONALITY__TYPE}`
  }
}
